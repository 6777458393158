import { getRequest } from "../connection/networkInteractive";
import { InteractiveVideo } from "./../models/InteractiveVideo";

const interactiveApi = {
  videos: "interactive-videos",
};

export const getInteractiveVideo = async (id, isDemo) => {
  try {
    if (isDemo) {
      // return await getInteractiveVideoDemo();
    }

    const {
      data: { data },
    } = await getRequest({
      endPoint: interactiveApi.videos,
      query: {
        senarioId: id,
      },
    });

    return data?.map((e) => InteractiveVideo.fromJSON(e));
  } catch (err) {
    console.log(err);
  }
};
