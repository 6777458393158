import VideoPlayer from "./components/VideoPlayer";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInteractiveAction } from "../../store/actions/interactive";
import { getPostsAction } from "../../store/actions/poll";
import { useVoice } from "../../hook/useVoice";

function PyxizTV() {
  const dispatch = useDispatch();
  const visitorId = useSelector((s) => s?.posts?.visitorId);

  const fetchPosts = useCallback(
    ({ page, next }) => {
      getPostsAction({
        page,
        limit: 6,
        // pageId: 141,
        next,
        // type: "polls",
        id: 2223,
        visitorId,
      })(dispatch);
    },
    [dispatch]
  );
  const fetchData = async ({ id, demo }) => {
    await getInteractiveAction(id, demo)(dispatch);
  };
  useEffect(() => {
    fetchData({ id: 14 });
    fetchPosts({ page: 1 });
  }, []);
  const voice = useVoice();

  return <VideoPlayer voice={voice} />;
}

export default PyxizTV;
