import { useState } from "react";
import Choices from "./Choices";
import Complete from "./Complete";
import CompleteList from "./CompleteList";
import MatchComponent from "./MatchComponent";

const Questions = ({
  activeStep,
  voice,
  idActive,
  handleClick,
  setAnswer,
  setActive,
  active,
  setIdActive,
}) => {
  const [status, setStatus] = useState("");
  return (
    <>
      {activeStep?.value === 0 && (
        <Choices
          voice={voice}
          question={"the track train is"}
          answer={1}
          choice={[
            { title: "Paris-Strasbourg", value: 1 },
            { title: "Paris-London", value: 2 },
            { title: "Strasbourg-Lyon", value: 3 },
          ]}
          setAnswer={setAnswer}
          handleClick={handleClick}
          idActive={idActive}
          activeStep={activeStep}
        />
      )}
      {activeStep?.value === 1 && (
        <Choices
          voice={voice}
          question={"The speed of the TGV is"}
          answer={2}
          choice={[
            { title: "200kmph", value: 1 },
            { title: "574.8kmph", value: 2 },
            { title: "155 kmph", value: 3 },
          ]}
          setAnswer={setAnswer}
          handleClick={handleClick}
          idActive={idActive}
          activeStep={activeStep}
        />
      )}
      {activeStep?.value === 2 && (
        <Complete
          voice={voice}
          question={`What function do wind turbines perform? \n wind turbines use wind to make……………….`}
          answer={"electricity"}
          setAnswer={setAnswer}
          handleClick={handleClick}
          idActive={idActive}
          activeStep={activeStep}
        />
      )}

      {activeStep?.value === 3 && (
        <Complete
          voice={voice}
          question={`What are the main advantages of wind turbines? `}
          answer={"Renewable energy- non-polluting energy source"}
          setAnswer={setAnswer}
          handleClick={handleClick}
          idActive={idActive}
          activeStep={activeStep}
        />
      )}

      {activeStep?.value === 5 && (
        <Complete
          voice={voice}
          question={`Which wind turbine component do the engineers discuss?`}
          answer={"The tower"}
          setAnswer={setAnswer}
          handleClick={handleClick}
          idActive={idActive}
          activeStep={activeStep}
        />
      )}
      {activeStep?.value === 6 && (
        <Complete
          voice={voice}
          question={`What is the big problem with offshore installations?`}
          answer={"Corrosion due to the presence of saltwater"}
          setAnswer={setAnswer}
          handleClick={handleClick}
          idActive={idActive}
          activeStep={activeStep}
        />
      )}
      {activeStep?.value === 7 && (
        <Complete
          voice={voice}
          question={`Which two types of construction material are being compared?`}
          answer={"Steel and reinforced concrete"}
          setAnswer={setAnswer}
          handleClick={handleClick}
          idActive={idActive}
          activeStep={activeStep}
        />
      )}
      {activeStep?.value === 8 && (
        <CompleteList
          voice={voice}
          title={` Make the following words negative by adding the prefixes in- or un-`}
          question={[
            "adequate",
            "efficient",
            "appropriate",
            "reliable",
            "consistent",
          ]}
          answer={[
            "inadequate",
            "inefficient",
            "inappropriate",
            "unreliable",
            "inconsistent",
          ]}
          idActive={idActive}
          activeStep={activeStep}
          setActive={setActive}
          active={active}
          setIdActive={setIdActive}
          status={status}
          setStatus={setStatus}
        />
      )}
      {activeStep?.value === 9 && (
        <MatchComponent
          voice={voice}
          idActive={idActive}
          activeStep={activeStep}
          setActive={setActive}
          active={active}
          setIdActive={setIdActive}
        />
      )}
    </>
  );
};

export default Questions;
