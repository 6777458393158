import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import {
  auth,
  interactive,
  authPoll,
  posts,
  chat,
  botApp,
  global,
} from "./reducers";

const store = configureStore({
  reducer: {
    auth,
    interactive,
    authPoll,
    posts,
    chat,
    botApp,
    global,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(thunkMiddleware);
  },
  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
