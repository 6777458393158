import { Box } from "@mui/material";
import ChatButton from "./ChatButton";

export default function MessageButtons({ choices }) {
  return (
    <Box width={1} display="flex" flexDirection="column">
      {choices.map((button) => (
        <Box width={1} sx={{ my: 0.5 }}>
          <ChatButton button={button} />
        </Box>
      ))}
    </Box>
  );
}
