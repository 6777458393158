import { Box } from "@mui/material";
import { Fragment, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import FullPage from "./Components/FullPage";
import { configAppAction } from "../../store/actions/botApp";

export default function ChatBot({
  apiKey,
  appId,
  url,
  identifier,
  senarioId,
  voice,
}) {
  const dispatch = useDispatch();

  const config = useCallback(() => {
    configAppAction({ apiKey, appId, url, identifier, senarioId })(dispatch);
  }, [appId, apiKey, dispatch, url, identifier, senarioId]);

  useEffect(() => {
    config && config();
  }, [config]);

  return (
    <Fragment>
      <Box sx={{ height: 1 }}>
        <FullPage voice={voice} />
      </Box>
    </Fragment>
  );
}
