import { ADD_MESSAGE, LOAD_CHAT } from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";
export const answerTypes = {
  postBack: 1,
  url: 2,
  call: 3,
};
export const questionTypes = {
  list: 1,
  buttons: 2,
  video: 3,
  textInput: 4,
};

const initialState = {
  messages: {
    loading: true,
    hasMore: true,
    data: [],
  },
};

const chatReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LOAD_CHAT, (s, { payload }) => {
      const { hasMore, data } = payload;
      s.messages.loading = false;
      s.messages.data = data;
      s.messages.hasMore = hasMore;
    })
    .addCase(ADD_MESSAGE, (s, { payload }) => {
      const message = payload;
      s.messages.data.push(message);
    });
});

export default chatReducer;
