import store from "../..";
import { sendNewMessageService } from "../../../services/ChatBot";
import { ADD_MESSAGE } from "../types";

const addUserMessage =
  ({ text }) =>
  (dispatch) => {
    const message = {
      sender: "user",
      text,
      createdAt: String(new Date()),
    };
    dispatch({ type: ADD_MESSAGE, payload: message });
  };

export const sendMessage =
  ({ text, userText, answerId, questionId }) =>
  (dispatch) => {
    const { apiKey, identifier, senarioId, baseURL } = store.getState().botApp;

    const messagePayload = {
      apiKey,
      senarioId,
      conversationId: identifier,
      text,
      answerId,
      questionId,
    };

    if (!userText) {
      userText = text;
    }

    if (userText) {
      addUserMessage({ text: userText })(dispatch);
    }

    sendNewMessageService({ baseURL, messagePayload }).then(({ data }) => {
      const receivedMessage = {
        text: data?.text,
        choices: data?.choices,
        sender: "bot",
        createdAt: String(new Date()),
        answerType: data?.answerType,
        media: data?.media,
      };
      dispatch({ type: ADD_MESSAGE, payload: receivedMessage });
    });
  };
