import axios from "axios";

export const getChatsService = async ({ baseURL, apiKey }) => {
  try {
    const token = localStorage.getItem("bot_user_auth_token");
    let url = baseURL + "/api/talk";

    const {
      data: { data },
    } = await axios.get(url, {
      params: {
        apiKey,
      },
      headers: {
        authorization: token || "",
      },
    });

    return { data };
  } catch (error) {
    console.error(error?.response);
    return { error: "Error While Fetching app" };
  }
};
