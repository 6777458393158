import { TOGGLE_NARRATOR } from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  narrator: true,
};
const globalReducer = createReducer(initialState, (builder) => {
  builder.addCase(TOGGLE_NARRATOR, (s) => {
    s.narrator = !s.narrator;
  });
});

export default globalReducer;
