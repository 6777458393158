import { Box, Button, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Complete = ({
  voice,
  question,
  answer,
  setAnswer,
  handleClick,
  idActive,
  activeStep,
}) => {
  setAnswer(answer);
  const [inputText, setInputText] = useState(idActive[activeStep.value] || "");
  const changeInputText = ({ target: { value } }) => {
    return setInputText(value);
  };
  const narrator = useSelector((s) => s?.global?.narrator);

  const makeVoice = useCallback(
    async ({ title }) => {
      if (voice && narrator) {
        await voice(title);
      }
    },
    [voice, narrator]
  );

  useEffect(() => {
    makeVoice({ title: question });
  }, [question, makeVoice]);
  return (
    <>
      <Box>
        <Typography variant="h6" fontWeight={700} sx={{ py: 3 }}>
          {question}
        </Typography>
      </Box>
      <Box sx={{ width: 1 }}>
        <TextField
          variant="standard"
          fullWidth
          onChange={changeInputText}
          value={inputText}
          multiline
          rows={2}
          sx={{
            border: "1px solid #777",
            backgroundColor: "background.secondary",
            borderRadius: "10px",
            p: 1,
          }}
          InputProps={{
            disableUnderline: true,
          }}
        />
      </Box>
      <Box sx={{ pt: 1 }}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          sx={{
            borderRadius: "20px",
            py: 1,
            flexGrow: 1,

            textTransform: "none",
          }}
          onClick={() => handleClick(inputText)}
        >
          submit
        </Button>
      </Box>
    </>
  );
};

export default Complete;
