import { getChatsService } from "../../../services/ChatBot";
import { LOAD_CHAT } from "../types";

export const getChatAction =
  ({ apiKey, baseURL }) =>
  (disaptch) => {
    if (apiKey) {
      getChatsService({ apiKey, baseURL }).then(({ error, data }) => {
        if (error) return console.error(error);

        const messages = data;

        disaptch({
          type: LOAD_CHAT,
          payload: {
            hasMore: true,
            data: messages,
          },
        });
      });
    }
  };
