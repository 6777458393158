import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { sendMessage } from "../../../../store/actions/chat";
import { answerTypes } from "../../../../store/reducers/chatReducer";

export default function ChatButton({ button }) {
  const dispatch = useDispatch();

  const sendNewMessage = ({ text, questionId, answerId }) => {
    return sendMessage({ userText: text, questionId, answerId })(dispatch);
  };

  const buttonClicked = (choice) => {
    const { questionId, id, valueId, type, typeValue } = choice;
    let answerId = valueId || id;

    if (typeValue) {
      if (type == answerTypes.url && typeValue) {
        return window?.open(typeValue, "_blank");
      }
      if (type == answerTypes.call && typeValue) {
        return window?.open(`tel:${typeValue}`, "_blank");
      }
    }

    return sendNewMessage({
      text: choice.text,
      questionId,
      answerId,
    });
  };

  return (
    <Button
      fullWidth
      size="small"
      color="primary"
      sx={{
        border: "1px solid",
        borderColor: "primary.default",
        boxShadow: "none",
        fontSize: 10,
      }}
      onClick={() => buttonClicked(button)}
    >
      {button?.text}
    </Button>
  );
}
