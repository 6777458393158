import { styled } from "@mui/material/styles";
import { Box, IconButton, Typography } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import CustomDrawer from "./CustomDrawer";
import { useState } from "react";
import { toggleNarratorAction } from "../../../store/actions/global";
import { useDispatch, useSelector } from "react-redux";
import SettingsDrawer from "./SettingsDrawer";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 5,
  mx: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export default function CustomizedSteppers({ activeStep, steps = [] }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const narrator = useSelector((s) => s?.global?.narrator);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <>
      <Box
        sx={{
          width: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton onClick={toggleDrawer(true)}>
          <SettingsOutlinedIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1, px: 1 }}>
          <BorderLinearProgress
            variant="determinate"
            value={(activeStep / (steps?.length - 1)) * 100}
          />
        </Box>
        <IconButton onClick={() => toggleNarratorAction()(dispatch)}>
          {narrator ? <MicNoneOutlinedIcon /> : <MicOffOutlinedIcon />}
        </IconButton>
      </Box>
      <Typography
        variant="caption"
        color={"black"}
        sx={{ textAlign: "center" }}
      >
        Resource: Cambridge English for Engineering
      </Typography>
      <CustomDrawer
        toggleDrawer={toggleDrawer}
        open={open}
        children={<SettingsDrawer />}
      />
    </>
  );
}
