import { Box, Typography, Container } from "@mui/material";
// import { chatBgVector, chatBgVectorS } from "../../assets";
import ChatBot from "./ChatBot";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

const apiKey = "a30b45708f687b044c26afce7ff1b2546805f1806aea166b";
const baseURL = "https://chatbot-api.limberx.com";
const senarioId = 25;

const Chat = ({ voice }) => {
  const theme = useTheme();
  const { t } = useTranslation(["chat"]);
  const isRtl = theme.direction === "rtl";
  return (
    <Box sx={{ pb: 5, px: 1 }}>
      <Container>
        <ChatBot
          apiKey={apiKey}
          url={baseURL}
          senarioId={senarioId}
          voice={voice}
        />
      </Container>
    </Box>
  );
};

export default Chat;
