import React, { useCallback, useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";

const MatchComponent = ({
  voice,
  idActive,
  activeStep,
  setActive,
  active,
  setIdActive,
}) => {
  const questions = [
    "appropriate",
    "consistent",
    "cost-effective",
    "effective",
    "efficient",
    "sufficient/adequate",
  ];
  const answers = [
    "suitable a the right solution for a particular situation",
    "reliable b good enough for the intended function",
    "economical c performs a function well",
    "works quickly and well",
    "makes the most of resources, isn’t wasteful",
    "doesn’t break down, always performs in the same way",
  ];

  const correctAnswers = ["B", "E", "C", "A", "F", "D"];

  const [selectedAnswers, setSelectedAnswers] = useState(
    idActive[activeStep.value] || Array(questions.length).fill("")
  );
  const availableOptions = Array.from({ length: answers.length }, (_v, i) =>
    String.fromCharCode(65 + i)
  );
  const [score, setScore] = useState(null);

  const evaluateAnswers = () => {
    let currentScore = 0;
    const updatedSelectedAnswers = selectedAnswers.map(
      (selectedAnswer, index) => {
        if (selectedAnswer === correctAnswers[index]) {
          currentScore++;
          return selectedAnswer;
        } else return "wrong";
      }
    );

    setSelectedAnswers(updatedSelectedAnswers);
    setScore(currentScore);

    const listArray = [...idActive];
    listArray[activeStep.value] = updatedSelectedAnswers;
    setIdActive(listArray);

    if (score === questions.length) {
      const updatedArray = [...active];
      updatedArray[activeStep.value] = "success";
      setActive(updatedArray);
    } else {
      const updatedArray = [...active];
      updatedArray[activeStep.value] = "fail";
      setActive(updatedArray);
    }
  };
  const narrator = useSelector((s) => s?.global?.narrator);

  const makeVoice = useCallback(
    async ({ title }) => {
      if (voice && narrator) {
        await voice(title);
      }
    },
    [voice, narrator]
  );

  useEffect(() => {
    makeVoice({ title: "Match the words to the definitions: " });
  }, [makeVoice]);

  return (
    <Box>
      <Typography variant="body2" sx={{ mb: "2em" }}>
        Match the words to the definitions:{" "}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "2em",
          marginBottom: "1em",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
            gap: "1em",
          }}
        >
          {questions?.map((question, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "1em",
                color: selectedAnswers[index] === "wrong" ? "red" : "inherit",
              }}
            >
              <Typography variant="subtitle2">{`${
                index + 1
              }. ${question}`}</Typography>
              <FormControl size="small">
                <Select
                  value={selectedAnswers[index]}
                  onChange={(e) =>
                    setSelectedAnswers(
                      selectedAnswers.map((answer, i) =>
                        i === index ? e.target.value : answer
                      )
                    )
                  }
                >
                  <MenuItem value="">Select answer</MenuItem>
                  {availableOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
            gap: "1em",
          }}
        >
          {answers?.map((answer, index) => (
            <Typography key={index} variant="subtitle2">{`${String.fromCharCode(
              65 + index
            )}. ${answer}`}</Typography>
          ))}
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        {score !== null && (
          <Typography variant="body2" sx={{ mt: "1em", mr: 2 ,}}>
            Score: {score} out of {questions.length}
          </Typography>
        )}
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          sx={{
            borderRadius: "20px",

            textTransform: "none",
          }}
          onClick={evaluateAnswers}
        >
          submit
        </Button>
      </Box>
    </Box>
  );
};

export default MatchComponent;
