import { CardMedia } from "@mui/material";
import React, { Fragment } from "react";
import { extentionURL, fileTypes } from "../../../../shared/fileTypes";

export default function Media({ url }) {
  const ext = extentionURL(url);

  const fileType = fileTypes[ext];

  const isFrame = String(url).includes("sprout");
  const isImage = String(fileType).includes("image");
  const isVideo = String(fileType).includes("video");

  return (
    <Fragment>
      {isFrame && <iframe src={url} />}
      {isImage && (
        <CardMedia
          component={"img"}
          src={url}
          height={200}
          sx={{ objectFit: "contain" }}
        />
      )}
    </Fragment>
  );
}
