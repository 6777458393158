import axios from "axios";

export const getAppService = async ({
  baseURL,
  identifier,
  apiKey,
  senarioId,
}) => {
  try {
    let url = baseURL + "/api/app";

    const {
      data: { data },
    } = await axios.get(url, {
      params: {
        identifier,
        apiKey,
        senarioId,
      },
    });

    return { data };
  } catch (error) {
    console.error(error?.response);
    return { error: "Error While Fetching app" };
  }
};
