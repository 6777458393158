import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Message from "./Message";

const chatBoxStyle = {
  overflowY: "scroll",
  height: 1,
  width: 1,
  overflowX: "hidden",
  pr: 1,
};
export default function Chat({ voice }) {
  const containerRef = useRef(null);

  const messages = useSelector((s) => s.chat.messages.data);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const element = containerRef.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [messages]);

  return (
    <Box sx={chatBoxStyle} ref={containerRef}>
      {messages.map((message, index) => {
        const isLast = messages.length - 1 === index;
        return (
          <Message
            message={message}
            key={index}
            isLast={isLast}
            voice={voice}
          />
        );
      })}
    </Box>
  );
}
