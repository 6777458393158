import { Box } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import Slider from "react-slick";
import ChatButton from "./ChatButton";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  nextArrow: <NavigateNextIcon color="primary" />,
  prevArrow: <NavigateBefore color="primary" />,
  centerMode: true,
  centerPadding: "60px",
};

export default function MessageList({ choices }) {
  return (
    <Box width={1} display="flex" flexDirection="column" sx={{ p: 1 }}>
      <Slider {...settings}>
        {choices.map((button) => (
          <Box sx={{ px: 1 }}>
            {button?.image && (
              <Box width={1} sx={{ height: 150 }}>
                <img
                  src={button?.image}
                  alt=""
                  style={{ objectFit: "cover", height: "100%", width: "100%" }}
                />
              </Box>
            )}
            {(button.valueId || button.id) && <ChatButton button={button} />}
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
