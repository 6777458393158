import { IconButton, TextField } from "@mui/material";
import { Box } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { sendMessage } from "../../../../store/actions/chat";
import { useTheme } from "@emotion/react";

const inputStyle = {
  borderRadius: "1em",
  px: 2,
  color: "primary.grey",
};

export default function WidgetInput() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isRtl = theme.direction === "rtl";

  const [inputText, setInputText] = useState("");

  const sendNewMessage = () => {
    if (!inputText) return;

    resetInput();

    return sendMessage({ text: inputText })(dispatch);
  };

  const changeInputText = ({ target: { value } }) => {
    return setInputText(value);
  };

  const resetInput = () => {
    return setInputText("");
  };

  const handleKeypress = (e) => {
    if (!inputText) return;
    if (e.keyCode === 13 && e.shiftKey === false) {
      if (e.preventDefault) {
        e.preventDefault();
      }
      return sendNewMessage();
    }
  };

  return (
    <Box display="flex" width={1} sx={inputStyle}>
      <TextField
        variant="standard"
        fullWidth
        onChange={changeInputText}
        value={inputText}
        onKeyDown={handleKeypress}
        multiline
        rows={3}
        sx={{
          border: "1px solid #777",
          backgroundColor: "background.secondary",
          borderRadius: "10px",
          mr: 2,
          px: 1,
        }}
        InputProps={{
          disableUnderline: true,
        }}
      />
      <IconButton
        sx={{
          width: "56px",
          height: "56px",
          backgroundColor: "background.secondary",
          display: "grid",
          placeItems: "center",
        }}
        size="small"
        onClick={sendNewMessage}
      >
        <SendIcon
          sx={{
            "-webkit-transform": isRtl && "scaleX(-1)",
            transform: isRtl && "scaleX(-1)",
          }}
        />
      </IconButton>
    </Box>
  );
}
