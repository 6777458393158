import { Box, Typography } from "@mui/material";
import { Fragment, useCallback, useEffect } from "react";
import Media from "./Media";
import MessageButtons from "./MessageButtons";
import MessageList from "./MessageList";
import { questionTypes } from "../../../../store/reducers/chatReducer";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { GoDependabot } from "react-icons/go";
import { useSelector } from "react-redux";

export default function Message({ message, isLast, voice }) {
  const isSender = message.sender === "user";

  const messageStyle = {
    maxWidth: "80%",
    borderRadius: "10px",
    p: 2,
    color: "text.secondary",
    backgroundColor: isSender ? "background.message" : "background.secondary",
  };

  const media = message.media;

  const narrator = useSelector((s) => s?.global?.narrator);

  const makeVoice = useCallback(
    async ({ title }) => {
      if (voice && narrator) {
        await voice(title);
      }
    },
    [voice, narrator]
  );

  useEffect(() => {
    if (isLast) {
      makeVoice({ title: message.text });
    }
  }, [message.text, isLast, makeVoice]);

  return (
    <Box
      dir={isSender ? "rtl" : "ltr"}
      width={1}
      sx={{
        p: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
      }}
    >
      <Box sx={messageStyle}>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          {isSender ? (
            <PersonOutlineIcon sx={{ fontSize: "30px" }} />
          ) : (
            <GoDependabot style={{ fontSize: "26px" }} />
          )}
          <Typography sx={{ my: media && 1, mx: 1 }}>{message.text}</Typography>
        </Box>

        {media && <Media url={media} />}
      </Box>

      {isLast && message.choices && message.choices?.length > 0 && (
        <Fragment>
          {message?.answerType === questionTypes.buttons && (
            <MessageButtons choices={message.choices} />
          )}

          {message?.answerType === questionTypes.list && (
            <MessageList choices={message.choices} />
          )}
        </Fragment>
      )}
    </Box>
  );
}
