import "./fonts.css";
import "./app.css";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { Provider } from "react-redux";
import store from "./store";
import PyxizTVComponent from "./pages/PyxizTV/index";
import { Box } from "@mui/material";

function App() {
  let theme = createTheme({
    typography: {
      fontFamily: "SofiaProMedium",
    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Box
          sx={{
            width: 1,
            height: "100vh",
            display: "grid",
            placeItems: "center",
            backgroundColor: "rgba(38,38,38,1)",
            position: "relative",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                borderRadius: "24px",
                width: "421.848px",
                height: "85vh",
                backgroundColor: "#fff",
                p: 1,
                zIndex: 2,
                position: "relative",
                overflow: "hidden",
              }}
            >
              <PyxizTVComponent />
            </Box>
            <Box
              sx={{
                width: "358.05px",
                height: "75vh",
                right: "-40px",
                background:
                  "linear-gradient(168.48deg, rgb(207, 221, 255), rgb(145, 145, 145) 83%)",
                top: "0px",
                bottom: "0px",
                position: "absolute",
                zIndex: 0,
                margin: "auto",
                transition: "0.2s ease-in-out",
                overflow: "hidden",
                borderRadius: "24px",
              }}
            />
            <Box
              sx={{
                width: "358.05px",
                height: "80vh",
                right: "-20px",
                background:
                  "linear-gradient(168.48deg, rgb(207, 221, 255), rgb(183, 181, 181) 83%)",
                top: "0px",
                bottom: "0px",
                position: "absolute",
                zIndex: 1,
                margin: "auto",
                transition: "0.2s ease-in-out",
                overflow: "hidden",
                borderRadius: "24px",
              }}
            />
          </Box>
        </Box>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
