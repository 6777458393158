import {
  Box,
  Divider,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";

const drawerBleeding = 56;

const Puller = styled("div")(({ theme }) => ({
  width: 40,
  height: 4,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  mt: 1,
}));

const CustomDrawer = ({ toggleDrawer, open, children, title }) => {
  const drawerRef = useRef(null);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      swipeAreaWidth={15}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          height: `calc(90% - ${drawerBleeding}px)`,
          maxWidth: "421.848px",
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
          left: `calc(50% - ${(drawerRef?.current?.clientWidth || 1) / 2}px)`,
        },
        ref: drawerRef,
      }}
    >
      <Box sx={{ width: 1, pb: 4, overflow: "hidden" }}>
        <Box
          sx={{ width: 1, display: "flex", justifyContent: "center", py: 1 }}
        >
          <Puller />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
            pb: 1,
          }}
        >
          <Typography>{title}</Typography>
          <IconButton onClick={toggleDrawer(false)} sx={{ color: "#000" }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ overflow: "auto" }}>{children}</Box>
    </SwipeableDrawer>
  );
};

export default CustomDrawer;
