import { Box, Divider } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import Chat from "../Chat";
import WidgetInput from "../Widget/WidgetInput";
import Loader from "../../../../components/Loader";

const boxStyle = {
  height: 1,
  width: 1,
  overflow: "hidden",
};

export default function FullPage({ voice }) {
  const loading = useSelector((state) => state.botApp.loading);

  if (loading)
    return (
      <Box sx={boxStyle}>
        <Loader />
      </Box>
    );

  return (
    <Box sx={boxStyle}>
      <Box display="flex" height={1} flexDirection="column">
        <Box flexGrow={1} width={1} overflow={"hidden"}>
          <Chat voice={voice} />
        </Box>
        <Divider />
        <Box sx={{ mt: 1 }} width={1}>
          <WidgetInput />
        </Box>
      </Box>
    </Box>
  );
}
