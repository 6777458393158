import { Box, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Choices = ({
  voice,
  question,
  choice,
  answer,
  handleClick,
  setAnswer,
  idActive,
  activeStep,
}) => {
  setAnswer(answer);
  const narrator = useSelector((s) => s?.global?.narrator);

  const makeVoice = useCallback(
    async ({ title }) => {
      if (voice && narrator) {
        await voice(title);
      }
    },
    [voice, narrator]
  );

  useEffect(() => {
    makeVoice({ title: question });
  }, [question, makeVoice]);
  return (
    <>
      <Box>
        <Typography variant="h4" fontWeight={700} sx={{ py: 3 }}>
          {question}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {choice?.map((e, i) => (
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => handleClick(e?.value)}
            >
              <Box
                sx={{
                  border: `2.5px solid ${
                    idActive[activeStep.value] === e.value ? "#007" : "#777"
                  }`,
                  borderRadius: 3,
                  width: 1,
                  height: { xs: "45px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  userSelect: "none",
                  cursor: "pointer",
                  backgroundColor:
                    idActive[activeStep.value] === e?.value
                      ? "lightblue"
                      : "transparent",
                }}
              >
                <Typography variant="body1" textAlign={"center"}>
                  {e?.title}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Choices;
