import { Box, Switch, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toggleNarratorAction } from "../../../store/actions/global";

const SettingsDrawer = () => {
  const dispatch = useDispatch();
  const checked = useSelector((s) => s?.global?.narrator);
  return (
    <>
      <Box sx={{ px: { xs: 1, sm: 2 } }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontWeight="bold">Voice over</Typography>
          <Switch
            checked={checked}
            onClick={() => toggleNarratorAction()(dispatch)}
          />
        </Box>
      </Box>
    </>
  );
};

export default SettingsDrawer;
