import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { AppBar, Box, Typography } from "@mui/material";
import PlayBtn from "../../../SVGs/PlayBtn";
import CustomizedSteppers from "./Features";

import { useFullScreenHandle } from "react-full-screen";
import { useSelector } from "react-redux";
import PollsComponent from "./PollsComponent";
import Footer from "./Footer";
import Questions from "../Questions";

function VideoPlayer({ voice }) {
  const posts = useSelector((s) => s.posts.data.byId);
  const allIds = useSelector((s) => s.posts.data.allIds);

  const steps = useMemo(
    () => [
      "Broadcast Radio",
      "Spinner",
      "Competition",
      "Challenge",
      "Survey",
      "Create Channel",
      "QuizList",
      "Interactive Book",
      "list",
      "match",
      // ...(allIds?.length > 0
      //   ? posts[allIds[0]]?.type?.poll?.questions?.map((q) => ({
      //       data: q,
      //       type: "quiz",
      //     }))
      //   : []),
    ],
    [posts, allIds]
  );

  const [isVideoPaused, setIsVideoPaused] = useState(true);
  const [activeStep, setActiveStep] = useState({ value: 0, data: steps[0] });

  const videoRef = useRef();

  const handleVideoPlay = () => {
    setIsVideoPaused(false);
    videoRef.current.play();
  };

  const handleVideoPause = () => {
    setIsVideoPaused(true);
    videoRef.current.pause();
  };

  const nextStep = () => {
    activeStep?.value < steps?.length &&
      setActiveStep((prev) => ({
        value: prev.value + 1,
      }));
  };

  const prevStep = () => {
    activeStep?.value !== 0 &&
      setActiveStep((prev) => ({
        value: prev.value - 1,
      }));
  };

  const handle = useFullScreenHandle();

  const soundRef = useRef();

  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(1);
  const [currentSegment, setCurrentSegment] = useState();

  const [selectedAction, setSelectedAction] = useState();
  const [updateState, setUpdateState] = useState(false);

  const [videoWidth, setVideoWidth] = useState();
  const [videoHeight, setVideoHeight] = useState();

  const interactive = useSelector((s) => s.interactive.data);

  useEffect(() => {
    updateVideoSize();
    window.addEventListener("resize", updateVideoSize);

    return () => {
      window.removeEventListener("resize", updateVideoSize);
    };
  }, [handle?.active]);

  useEffect(() => {
    let s =
      Array.isArray(interactive) &&
      interactive?.find((e) => e?.index == currentSegmentIndex);
    setCurrentSegment(s);
  }, [interactive?.length, currentSegmentIndex]);

  const updateVideoSize = () => {
    setVideoWidth(
      handle.active
        ? window?.screen?.width
        : videoRef?.current?.getBoundingClientRect().width
    );
    setVideoHeight(
      handle.active
        ? window?.screen?.height
        : videoRef?.current?.getBoundingClientRect().height
    );
  };

  const refreshState = () => {
    setUpdateState(!updateState);
  };

  const handleSegmentEnd = (instantAction) => {
    if (instantAction != null) {
      setCurrentSegmentIndex(instantAction?.value);
    } else if (selectedAction != null) {
      setCurrentSegmentIndex(selectedAction?.value);
    } else if (!currentSegment?.isLoop) {
      setCurrentSegmentIndex(currentSegment?.index + 1);
    }

    disableAllActions();
    setSelectedAction(null);
  };

  const handleActionClick = async (action) => {
    if (selectedAction == null) {
      setSelectedAction(action);

      playActionSound();

      if (currentSegment?.isLoop) {
        handleSegmentEnd(action);
      }
    }
  };

  const disableAllActions = () => {
    if (currentSegment?.actions) {
      for (let action of currentSegment?.actions) {
        action = { ...action, show: false };
        refreshState();
      }
    }
  };

  const playActionSound = () => {
    const audioPlayer = soundRef.current;
    audioPlayer?.play();
  };

  // listen question
  const textList = `Mike, Loreta and Hanif, engineers at a wind turbine constructor, are discussing performance and suitability issues relating to offshore wind turbines. Listen to the conversation and answer the following questions.\n
Mike: Obviously, a tubular steel tower only gives you sufficient struct Ural strength if you give it adequate protection from corrosion - fl,e big problem with off shore installations. So, technically, you could say steel is inappropriate in t hat environment .\n Loreta: They make ships out of it. Mike: I know , Loret a, but only because t here's no cost-effective alternative. But we're not talking about ships, we're 
talking about fixed structures. The point is, I think we should look more seriously at alternatives to all-steel supports.
 And the obvious alternative is reinforced concrete.\n Loreta: We've already looked into it , though, and it wasn't cost-effective.\n Mike: Not in the short term. But we didn't really look into it properly over the long term.\n Loreta: But you made t he point yourself, Mike, that steel's completely ineffective if it's corroded. And one of the main constituents of reinforced concrete is steel.\n Mike: lt's protected, though, isn't it ? lt's embedded inside concrete. That's a much more effective protection than paint.\n Loreta: Not necessarily. Lf we're talking about the long term, as you say, what happens to concrete when it's exposed to the sea for a few years? lt erodes. Which means the steel eventually gets exposed. You look at concrete coastal defences.
  How often do you see the concrete all crumbling away, and all the steel exposed
`;
  const narrator = useSelector((s) => s?.global?.narrator);

  const makeVoice = useCallback(
    async ({ title }) => {
      if (voice && activeStep?.value === 4) {
        await voice(title, narrator);
      }
    },
    [voice, activeStep?.value, narrator]
  );

  useEffect(() => {
    if (activeStep?.value === 4) {
      makeVoice({ title: textList });
    }
  }, [activeStep?.value]);

  const selectedOption =
    steps[activeStep?.value]?.type === "quiz" &&
    steps[activeStep?.value]?.data?.options?.filter(
      (e) => e?.id === steps[activeStep?.value]?.data?.myChosenOption?.optionId
    );

  const isRightAnswer = selectedOption?.length
    ? selectedOption[0]?.isRightAnswer
    : undefined;
  const status = { true: "success", false: "fail" }[isRightAnswer];

  const [active, setActive] = useState([]);
  const [answer, setAnswer] = useState("");
  const [idActive, setIdActive] = useState([]);
  const handleClick = useCallback(
    (value) => {
      const listArray = [...idActive];
      listArray[activeStep.value] = value;
      setIdActive(listArray);

      if (value === answer) {
        const updatedArray = [...active];
        updatedArray[activeStep.value] = "success";
        setActive(updatedArray);
      } else {
        const updatedArray = [...active];
        updatedArray[activeStep.value] = "fail";
        setActive(updatedArray);
      }
    },
    [answer]
  );
  console.log(activeStep?.value < steps?.length - 1);
  return (
    <Box sx={{ width: 1 }}>
      <AppBar
        position="relative"
        sx={{ backgroundColor: "transparent", boxShadow: "none", py: 2, px: 1 }}
      >
        {activeStep?.value < steps?.length - 1 && (
          <CustomizedSteppers
            activeStep={activeStep?.value}
            totalLength={0}
            steps={steps}
          />
        )}
      </AppBar>

      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "24px",
        }}
      >
        {activeStep?.value <= 1 && (
          <video
            src="./images/videoplayback.mp4"
            style={{ width: "100%", height: "150px", objectFit: "cover" }}
            onPlay={handleVideoPlay}
            onPause={handleVideoPause}
            ref={videoRef}
          />
        )}
        {(activeStep?.value === 2 || activeStep?.value === 3) && (
          <img
            src="./images/blob.jfif"
            alt="turbines"
            style={{ width: "80%", height: "170px", objectFit: "cover" }}
          />
        )}
        {activeStep?.value === 4 && (
          <Box sx={{ overflow: "auto", p: 2, mb: 4, height: "440px" }}>
            <Typography variant="body1">{textList}</Typography>
          </Box>
        )}
        {activeStep?.value === steps?.length && (
          <Box
            sx={{
              overflow: "auto",
              p: 2,
              mb: 4,
              height: "440px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" fontSize={900} textAlign={"center"}>
              Congratulations, you have reached the end
            </Typography>
          </Box>
        )}
        {isVideoPaused && activeStep?.value <= 1 && (
          <>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
                zIndex: 10,
                fill: "#f9f9f9",
                stroke: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: "30px",
                    sm: "60px",
                    md: "80px",
                    xl: "100px",
                  },
                  cursor: "pointer",
                  transition: "0.5s all",
                }}
                onClick={handleVideoPlay}
              >
                <PlayBtn />
              </Box>
            </Box>

            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 1,
                pointerEvents: "none",
              }}
            >
              <img
                src="/images/videobgd.svg"
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
          </>
        )}

        {!isVideoPaused && activeStep?.value <= 1 && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 10,
            }}
            onClick={handleVideoPause}
          ></Box>
        )}
      </Box>
      <Box sx={{ overflow: "auto", maxHeight: "58vh", py: 2 }}>
        <Questions
          activeStep={activeStep}
          voice={voice}
          handleClick={handleClick}
          idActive={idActive}
          setAnswer={setAnswer}
          setActive={setActive}
          active={active}
          setIdActive={setIdActive}
        />
      </Box>

      {/* <Box sx={{ overflow: "auto", maxHeight: "38vh" }}>
        {steps[activeStep?.value]?.type === "quiz" && (
          <PollsComponent
            voice={voice}
            question={steps[activeStep?.value]?.data}
          />
        )}
      </Box> */}

      <Footer
        nextStep={nextStep}
        prevStep={prevStep}
        status={status}
        active={active[activeStep.value]}
        voice={voice}
        activeStep={activeStep?.value}
        steps={steps?.length}
      />
    </Box>
  );
}

export default VideoPlayer;
