import { getAppService } from "../../../services/ChatBot";
import { getChatAction } from "../chat";
import { BOT_APP_CONFIG } from "../types";
import { v4 } from "uuid";

export const configAppAction =
  ({ apiKey, appId, url: baseURL, identifier, senarioId }) =>
  (dispatch) => {
    //  if (!apiKey || !appId) throw Error("appId and apiKey are both required");
    if (!identifier) {
      identifier = localStorage.getItem(`chatbot_identifier`) || v4();
      localStorage.setItem(`chatbot_identifier`, identifier);
    }

    if (!senarioId) throw Error("No Senarios");

    return getAppService({ baseURL, apiKey, identifier, senarioId }).then(
      ({ error, data }) => {
        if (error) return console.error(error);

        localStorage.setItem("bot_user_auth_token", data.auth.token);

        dispatch({
          type: BOT_APP_CONFIG,
          payload: {
            apiKey,
            appId,
            baseURL,
            app: data.app,
            identifier,
            senarioId,
          },
        });

        getChatAction({ baseURL, apiKey })(dispatch);
      }
    );
  };
