import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CompleteList = ({
  voice,
  title,
  question,
  answer,
  setIdActive,
  active,
  setActive,
  idActive,
  activeStep,
  status,
  setStatus,
}) => {
  const [inputs, setInputs] = useState(
    idActive[activeStep.value] || Array(question.length).fill("")
  ); // User inputs
  const [isCorrect, setIsCorrect] = useState(
    status || Array(question.length).fill(true)
  ); // Validation statuses

  const changeInputText = (index) => (event) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);
  };

  const checkAnswers = () => {
    const newIsCorrect = inputs.map((input, index) => input === answer[index]);
    setIsCorrect(newIsCorrect);
    setStatus(newIsCorrect);

    let currentScore = 0;
    newIsCorrect.map((selectedAnswer) => {
      if (selectedAnswer === true) {
        currentScore++;
      } else return "wrong";
    });

    const listArray = [...idActive];
    listArray[activeStep.value] = inputs;
    setIdActive(listArray);

    if (currentScore === question.length) {
      const updatedArray = [...active];
      updatedArray[activeStep.value] = "success";
      setActive(updatedArray);
    } else {
      const updatedArray = [...active];
      updatedArray[activeStep.value] = "fail";
      setActive(updatedArray);
    }
  };
  const narrator = useSelector((s) => s?.global?.narrator);

  const makeVoice = useCallback(
    async ({ title }) => {
      if (voice && narrator) {
        await voice(title);
      }
    },
    [voice, narrator]
  );

  useEffect(() => {
    makeVoice({ title: question });
  }, [question, makeVoice]);
  return (
    <>
      <Box>
        <Typography variant="h6" fontWeight={700} sx={{ py: 3 }}>
          {title}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {question?.map((e, i) => (
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                textAlign={"center"}
                sx={{ color: isCorrect[i] ? "inherit" : "red" }}
              >
                {e}:........
              </Typography>
              <Box sx={{ width: 1, px: 2 }}>
                <TextField
                  variant="standard"
                  fullWidth
                  onChange={changeInputText(i)}
                  value={inputs[i]}
                  sx={{
                    border: "1px solid #777",
                    backgroundColor: "background.secondary",
                    borderRadius: "10px",
                    px: 1,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ pt: 1 }}>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            borderRadius: "20px",
            py: 1,
            flexGrow: 1,
            textTransform: "none",
          }}
          onClick={checkAnswers}
          fullWidth
        >
          submit
        </Button>
      </Box>
    </>
  );
};

export default CompleteList;
