import {
  AppBar,
  Box,
  Button,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import Chat from "../../Chat";
import ChatIcon from "@mui/icons-material/Chat";
import CustomDrawer from "./CustomDrawer";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { useState } from "react";

const Footer = ({
  nextStep,
  prevStep,
  status,
  voice,
  active,
  activeStep,
  steps,
}) => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  return (
    <>
      <CustomDrawer
        toggleDrawer={toggleDrawer}
        open={open}
        title={"ChatBot"}
        children={<Chat voice={open && voice} />}
      />
      <AppBar
        position="absolute"
        sx={{
          backgroundColor: "#fff",
          boxShadow: "none",
          pb: 2,
          px: 1,
          bottom: 0,
          top: "unset",
        }}
      >
        {activeStep === steps && (
          <Box sx={{ backgroundColor: "#F9E9E9" }}>
            <Typography
              variant="body2"
              // fontWeight="bold"
              color={"black"}
              textAlign={"center"}
            >
              Note: Speaking or others features are applicable will need it
            </Typography>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            sx={{ backgroundColor: "#fff" }}
            color="primary"
            onClick={toggleDrawer("bottom", true)}
          >
            <ChatIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 1,
            boxSizing: "border-box",
            pt: 1,
          }}
        >
          <IconButton onClick={prevStep}>
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
          <Button
            variant="contained"
            color="secondary"
            disabled={!status && !active && activeStep !== 4}
            sx={{
              borderRadius: "20px",
              py: 1,
              flexGrow: 1,
              ml: 2,
              textTransform: "none",
              backgroundColor: {
                success: "#08C85F",
                fail: "#EF4444",
                info: "#0057FF",
              }[status || active],
            }}
            onClick={nextStep}
          >
            Continue
          </Button>
        </Box>
        <Slide
          direction="up"
          easing="ease-in-out"
          in={Boolean(status || active)}
          appear={Boolean(status || active)}
          mountOnEnter
          unmountOnExit
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: -1,
          }}
        >
          <Box
            sx={{
              borderRadius: "24px 24px 0px 0px",
              pt: 1,
              minHeight: "110px",
              boxSizing: "border-box",
              backgroundColor: {
                success: "rgba(220,252,231,1)",
                fail: "rgba(254,226,226,1)",
                info: "rgba(219,234,254,1)",
              }[status || active],
              color: {
                success: "rgba(22,163,74,1)",
                fail: "rgba(220,38,38,1)",
                info: "rgba(37,99,235,1)",
              }[status || active],
              px: 2,
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              {
                {
                  success: "Well done! 😎",
                  fail: "Incorrect!",
                  info: "Superb 🥹",
                }[status || active]
              }
            </Typography>
          </Box>
        </Slide>
      </AppBar>
    </>
  );
};

export default Footer;
